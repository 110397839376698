:root {
  font-family: 'Barlow', sans-serif !important;
  --color-bg: #f6f9fc;
  -webkit-align-items: top !important;
  -webkit-box-align: top !important;
  -ms-flex-align: top !important;
  align-items: top !important;
}

body {
  font-family: 'Barlow', sans-serif !important;
  margin: 0px !important;
  padding: 0px !important;
}

html {
  font-family: 'Barlow', sans-serif !important;
  background-color: var(--color-bg);
}

:global(code) {
  font-family: 'Barlow', sans-serif !important;
}